
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Head from 'next/head';


const FallBackComponent = ({error,resetErrorBoundary}) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  

  return <>
    <Head>
      <title>BANKAYO | Server Error</title>
    </Head>
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        minHeight: '100%',
        px: 3,
        py: '80px'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          color="textPrimary"
          variant={mobileDevice ? 'h4' : 'h1'}
        >
          Runtime Error
        </Typography>
        <Alert
          severity="error"
        >
          {error.message}
        </Alert>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Box
            alt="Server Error"
            component="img"
            src={`/static/error/error-500.png`}
            sx={{
              height: 'auto',
              maxWidth: '100%',
              width: 400
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          
          <Button
            onClick={resetErrorBoundary}
            color="primary"
            variant="outlined"
          >
            Reset Error
          </Button>
          
          
        </Box>
      </Container>
    </Box>
  </>;
};

export default FallBackComponent;
