import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { THEMES } from '../constants';

const initialSettings = {
  compact: true,
  direction: 'ltr',
  tenant:"default",
  isBusinessDateEnabled:false,
  dateFormat:"dd MMM yyyy",
  dateMonthFormat:"dd MMM",
  locale:"en",
  currencyCode:"UGX",
  fullname:process.env.orgName?process.env.orgName:"MICROFINANCE NAME",
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: THEMES.LIGHT
};

export const restoreSettings = () => {
  let settings = null;

  const setOrgName = process.env.orgName?{fullname:process.env.fullname}:{}

  try {
    const storedData = window.localStorage.getItem('settings');

    if (storedData) {
      settings = {
        ...initialSettings,
        ...(JSON.parse(storedData)),
        ...setOrgName
        
      };
    } else {
      settings = {
        compact: true,
        direction: 'ltr',
        fullname:process.env.orgName?process.env.orgName:"MICROFINANCE NAME",
        tenant:"default",
        dateFormat:"dd MMM yyyy",
        dateMonthFormat:"dd MMM",
        locale:"en",
        currencyCode:"UGX",
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: window.matchMedia('(prefers-color-scheme: dark)').matches
          ? THEMES.DARK
          : THEMES.LIGHT
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings) => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: () => { },
  setBusinessDateEnabled:()=>{},
  setBusinessDate: ()=>{},
  businessDate:null,
  isBusinessDateEnabled:false,
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState(initialSettings);
  const [businessDate,setBusinessDate] = useState(null)
  const [isBusinessDateEnabled,setBusinessDateEnabled] = useState(false)

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings({...restoredSettings,isBusinessDateEnabled,setBusinessDateEnabled,businessDate});
    }
  }, [isBusinessDateEnabled,setBusinessDateEnabled,businessDate]);

  const saveSettings = (updatedSettings) => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
        setBusinessDate,
        businessDate,
        setBusinessDateEnabled,
        isBusinessDateEnabled,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
