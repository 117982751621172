import Image from 'next/image';
import React from 'react';

const Logo = (props) => {
  return (
    <Image
      alt="Logo"
      /* src="/static/logo.svg" */
      src={"/static/logo.png"}
      {...props}
      fill={true}
    />
  );
}

export default Logo;
