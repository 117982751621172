// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const url = process.env.URL;

const serverInfoUrl = url.replace("api/v1","actuator/info")

const registrationUrl = process.env.REGISTRATION_URL;

export const fineractApi = createApi({
  reducerPath: 'fineractApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers) => {
      const settings = localStorage.getItem("settings")
      
      const tenant = settings?(JSON.parse(settings)).tenant:process.env.TENANT;
      const fineractUser = localStorage.getItem('fineractAccessToken');

      if (fineractUser) {
        const { base64EncodedAuthenticationKey } = JSON.parse(fineractUser);
        headers.set('Authorization', 'Basic ' + base64EncodedAuthenticationKey);
      }
      headers.set('Fineract-Platform-TenantId', tenant);
      return headers;
    },

  }),
  keepUnusedDataFor: 30,
  tagTypes:[
    'LoansForDisbursal',
    'Documents',
    'Tellers',
    'Cashiers',
    'Cashier',
    'CashierTransactions',
    'Jobs',
    'Closures',
    'StandingInstructions',
    'ClientStandingInstructions',
    'LoansForApproval',
    'Configurations',
    'SavingsAccount',
    'Loan',
    'ActiveLoans',
    'WorkingDays',
    'Holidays',
    'Client',
    'ClientLoans',
    'ClientSavings',
    'CashierSummary',
    'ClientShares',
    'ClientIdentities',
    'ShareDividends',
    'ShareDividendDetail',
    'ClientFamily',
    'ClientAddresses',
    'ClientImages',
    'LoanPaymentTemplate',
    'Notes',
    'LoanCollaterals',
    'GLAccounts',
    'MakerChecker',
    'UserMakerCheckers',
    'RolePermissions',
    'GLAccount',
    'PaymentTypes',
    'ShareAccount',
    'ShareAccounts',
    'Groups',
    'Group',
    'Offices',
    'Office',
    'SchedulerStatus',
    'Report',
    'Reports',
    'Funds',
    'Clients',
    'ClientCharges',
    'Charges',
    'FinancialActivityMappings',
    'ExternalServicesConfigs',
    'Roles',
    'CodeValues',
    'NPALoans',
    'Users',
    'Roles',
    'LoansForReschedule',
    'GroupAccounts',
    'ProvisioningEntries',
    'Datatables',
    'SMSCampaigns',
    'FixedDepositProducts',
    'FixedDepositProduct',
    'AccountNumberFormats',
    'LoanProducts',
    'LoanProductMix',
    'LoanProductMixTemplate',
    'ProvisioningCriteria',
    'ProvisioningCriteriaDetail',
    'ProvisioningEntryDetails',
    'ProvisioningEntryItems',
    'RecurringDepositProduct',
    'RecurringDepositProducts',
    'BusinessDate',
    'COBDate',
    'ConfigurationDetail',
    'SavingsAccounts',
    'FixedDepositAccounts',
    'RecurringDepositAccounts',
    'AccountingRules',
    'Notifications',
    'RecurringDepositAccount',
    'GuarantorAccounts',
    'Staff'
    
  ],
  endpoints: (builder) => ({
    getServerInfo: builder.query({
      query: () =>({
        url: serverInfoUrl
      })
    }),

    getBusinessDate: builder.query({
      query: () =>({
        url: `businessdate/BUSINESS_DATE`
      }),
      providesTags:["BusinessDate"]
    }),
    getCOBDate: builder.query({
      query: () =>({
        url: `businessdate/COB_DATE`
      }),
      providesTags:["COBDate"]
    }),
    setBusinessDate: builder.mutation({
      query: (body) =>({
        url: `businessdate`,
        method:"POST",
        body
      }),
      invalidatesTags:["BusinessDate","COBDate"]
    }),

    getResourceNotes: builder.query({
      query: ({resource,resourceId}) =>({
        url:`${resource}/${resourceId}/notes`
      }),
      providesTags:["Notes"]
    }),

    createResourceNote: builder.mutation({
      query: (body) =>{
        const {resource,resourceId,note} = body
        return {
          url: `${resource}/${resourceId}/notes`,
          method:"POST",
          body:{note}
        }
      },
      invalidatesTags:["Notes"]
    }),

    updateResourceNote: builder.mutation({
      query: (body) =>{
        const {resource,resourceId,noteId,note} = body
        return {
          url: `${resource}/${resourceId}/notes/${noteId}`,
          method:"PUT",
          body:{note}
        }
      },
      invalidatesTags:["Notes"]
    }),

    deleteResourceNote: builder.mutation({
      query: (body) =>{
        const {resource,resourceId,noteId} = body
        return {
          url: `${resource}/${resourceId}/notes/${noteId}`,
          method:"DELETE",
          
        }
      },
      invalidatesTags:["Notes"]
    }),


    getProvisioningCriteria: builder.query({
      query: () => `provisioningcriteria`,
      providesTags:["ProvisioningCriteria"]
    }),
    

    retrieveProvisioningCriteriaDetail: builder.query({
      query: id => `provisioningcriteria/${id}`,
      providesTags:["ProvisioningCriteriaDetail"]
    }),

    retrieveProvisioningCriteriaTemplate: builder.query({
      query: id => `provisioningcriteria/${id}?template=true`,
      
    }),

    createProvisioningCriteria: builder.mutation({
      query: body =>{
        return {url:`provisioningcriteria`,method:"POST",body}
      },
      invalidatesTags:["ProvisioningCriteria"]
    }),

    updateProvisioningCriteria: builder.mutation({
      query: body =>{
        const {id,...rest} = body
        return {url:`provisioningcriteria/${id}`,method:"PUT",body:rest}
      },
      invalidatesTags:["ProvisioningCriteria"]
    }),

    deleteProvisioningCriteria: builder.mutation({
      query: id =>{
        
        return {url:`provisioningcriteria/${id}`,method:"DELETE",body:{}}
      },
      invalidatesTags:["ProvisioningCriteria"]
    }),

    getDocuments: builder.query({
      query: ({entityType,entityId}) => `${entityType}/${entityId}/documents`,
      providesTags:["Documents"]
    }),

    createDocument: builder.mutation({
      query: ({entityType,entityId,body}) => {
        
        return {
          url:`${entityType}/${entityId}/documents`,
          method:"POST",
          //headers: {"Content-Type":"multipart/form-data"},
          body
        }
        
      },
      invalidatesTags:["Documents"]
    }),
    updateDocument: builder.mutation({
      query: (params) => {
        const {entityType,entityId,body,id} = params
        return {
          url:`${entityType}/${entityId}/documents/${id}`,
          method:"PUT",
          body:{...body}
        }
        
      },
      /* prepareHeaders: headers =>{
        if(file){
          headers.set("Content-Type","multipart/form-data")
        }
        return headers
      }, */
      
      invalidatesTags:["Documents"]
    }),

  removeDocument: builder.mutation({
    query: ({entityType,entityId,id}) => ({
      url:`${entityType}/${entityId}/documents/${id}`,
      method:"DELETE",
    }),
    invalidatesTags:["Documents"]
  }),

  getDocumentAttachment: builder.query({
    query: ({entityType,entityId,id}) =>({
      url:`${entityType}/${entityId}/documents/${id}/attachment`,
      responseHandler: async(res) => res.blob()
    })
  }),

    //account number formats
    getAccountNumberFormat: builder.query({
      query: () => `accountnumberformats`,
      providesTags:["AccountNumberFormats"]
    }),
    createAccountNumberFormat: builder.mutation({
      query: (body) => {
        return {url:`accountnumberformats`, method:'POST', body}
      },
      invalidatesTags:["AccountNumberFormats"]
    }),
    updateAccountNumberFormat: builder.mutation({
      query: (body) => {
        const {id, ...rest} = body
        return {url:`accountnumberformats/${id}`, method:'PUT', body:rest}
      },
      invalidatesTags:["AccountNumberFormats"]
    }),
    //client charges
    getClientChargesTemplate: builder.query({
      query: (clientId) => `clients/${clientId}/charges/template`
    }),
    //charge template
    getChargeTemplate: builder.query({
      query: (chargeId) => `charges/${chargeId}?template=true`
    }),
    //Add Charge
    addClientCharge: builder.mutation({
      query: (body) => {
        const { accountId, ...rest } = body;
        return {
          url: `clients/${accountId}/charges`,
          method: 'POST',
          body: rest
        };
      }
    }),

    waiveClientCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId,...rest } = body;
        return {
          url: `clients/${accountId}/charges/${chargeId}?command=waive`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Client"]
    }),

    deleteClientCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId,...rest } = body;
        return {
          url: `clients/${accountId}/charges/${chargeId}?command=delete`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Client"]
    }),
    
    //Pay Charge
    payClientCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId, ...rest } = body;
        return {
          url: `clients/${accountId}/charges/${chargeId}?command=paycharge`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["ClientCharges"]
    }),
    getClientCharges: builder.query({
      query: (clientId) => `clients/${clientId}/charges`,
      providesTags: ["ClientCharges"]
    }),
    //Add Family
    addClientFamily: builder.mutation({
      query: (body) => {
        const { clientId, ...rest } = body;
        return {
          url: `clients/${clientId}/familymembers`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["ClientFamily"]
    }),
    //Get Client Family
    getClientFamily: builder.query({
      query: (clientId) => `clients/${clientId}/familymembers`,
      providesTags:["ClientFamily"]
    }),
    addClientNote: builder.mutation({
      query: (body) => {
        const { clientId, ...rest } = body;
        return {
          url: `clients/${clientId}/notes`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Notes"]
    }),
    getClientNotes: builder.query({
      query: id => `clients/${id}/notes`,
      providesTags:["Notes"]
    }),
    // savings applications
    createSavingsApplication: builder.mutation({
      query: (body) => {
        return { url: `savingsaccounts`, method: 'POST', body };
      },
      invalidatesTags:["ClientSavings","GroupAccounts"]
    }),
    createSavingsCharge: builder.mutation({
      query: (body) => {
        const { accountId,accountType, ...rest } = body;
        return {
          url: `savingsaccounts/${accountId}/charges`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    deleteSavingsCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId } = body;
        return {
          url: `savingsaccounts/${accountId}/charges/${chargeId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    waiveSavingsCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId,...rest } = body;
        return {
          url: `savingsaccounts/${accountId}/charges/${chargeId}?command=waive`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    //Pay Charge
    paySavingsCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId,amount,locale,transactionDate,dateFormat } = body;
        return {
          url: `savingsaccounts/${accountId}/charges/${chargeId}?command=paycharge`,
          method: 'POST',
          body: {amount,locale,dueDate:transactionDate,dateFormat}
        };
      },
      invalidatesTags: ['SavingsAccount']
    }),

    getSavingsAccountTemplateForEditing: builder.query({
      query: ({accountId,accountType}) =>`${accountType}/${accountId}?associations=charges&template=true`
    }),

    getSavingsAccountTemplate: builder.query({
      query: ({entityType,entityId,accountType}) => {
        
        const entityTypes = {individual:"clientId", group:"groupId"}
        return{url:`${accountType}/template?${entityTypes[entityType]}=${entityId}`}}
    }),
    getSavingsApplications: builder.query({
      query: () => `savingsaccounts?limit=0`,
      providesTags:["SavingsAccounts"]
    }),
    getRecurringDepositAccounts: builder.query({
      query: () => `recurringdepositaccounts?limit=0`,
      providesTags:["RecurringDepositAccounts"]
    }),
    getFixedDepositAccounts: builder.query({
      query: () => `fixeddepositaccounts?limit=0`,
      providesTags:["FixedDepositAccounts"]
    }),
    getSavingsAccountDetails: builder.query({
      query: ({accountType,id}) => `${accountType}/${id}?associations=all`,
      providesTags: ['SavingsAccount']
    }),
    getRecurringDepositAccountDetails: builder.query({
      query: (id) => `recurringdepositaccounts/${id}`,
      providesTags: ['RecurringDepositAccount']
    }),
    getSavingsAccountCharges: builder.query({
      query: ({accountType,id}) => `${accountType}/${id}/charges`
    }),
    getSavingsTransactionTemplate: builder.query({
      query: ({accountType,accountId,command}) => `${accountType}/${accountId}/transactions/template?command=${command}`
    }),
    depositFunds: builder.mutation({
      query: (body) => {
        const { accountId,accountType, ...transaction } = body;
        return {
          url: `${accountType}/${accountId}/transactions?command=deposit`,
          method: 'POST',
          body: transaction
        };
      },
      invalidatesTags: ['SavingsAccount','Client']
    }),
    depositFundsNoRefetch: builder.mutation({
      query: (body) => {
        const { accountId, ...transaction } = body;
        return {
          url: `savingsaccounts/${accountId}/transactions?command=deposit`,
          method: 'POST',
          body: transaction
        };
      }
    }),
    withdrawFunds: builder.mutation({
      query: (body) => {
        const { accountId,accountType, ...patch } = body;
        return {
          url: `${accountType}/${accountId}/transactions?command=withdrawal`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags: ['SavingsAccount','Client']
    }),
    updateRecommendedDepositAmount: builder.mutation({
      query: (body) => {
        const { accountId,accountType, ...patch } = body;
        return {
          url: `${accountType}/${accountId}?command=withdrawal`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags: ['SavingsAccount']
    }),
    undoTransaction: builder.mutation({
      query: ({ accountId, transactionId }) => {
        return {
          url: `savingsaccounts/${accountId}/transactions/${transactionId}?command=undo`,
          method: 'POST'
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    holdAmount: builder.mutation({
      query: (body) => {
        const { accountId, ...patch } = body;
        return {
          url: `savingsaccounts/${accountId}/transactions?command=holdAmount`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    modifyTransaction: builder.mutation({
      query: (body) => {
        const { accountId,accountType,transactionId, ...patch } = body;
        return {
          url: `${accountType}/${accountId}/transactions/${transactionId}?command=modify`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    releaseAmount: builder.mutation({
      query: (body) => {
        const { accountId,transactionId } = body;
        return {
          url: `savingsaccounts/${accountId}/transactions/${transactionId}?command=releaseAmount`,
          method: 'POST',
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    approveSavingsApplication: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=approve`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    

    undoApprovalSavingsApplication: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=undoApproval`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    rejectSavingsApplication: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=reject`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    withdrawSavingsApplication: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=withdrawnByApplicant`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    prematureCloseTermDepositAccount: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=prematureClose`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    calculatePrematureTermDepositAccountAmount: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=calculatePrematureAmount`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    calculateTermDepositAccountInterest: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=calculateInterest`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    
    postTermDepositAccountInterest: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=postInterest`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    activateSavingsApplication: builder.mutation({
      query: (body) => {
        const { id,accountType, ...patch } = body;
        return {
          url: `${accountType}/${id}?command=activate`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),
    closeSavingsAccount: builder.mutation({
      query: (body) => {
        const { id, type, ...patch } = body;
        return {
          url: `${type}/${id}?command=close`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["SavingsAccount"]
    }),

    //account transfers
    getAccountTransfers: builder.query({
      query: () => `accounttransfers`
    }),

    createAccountTransfer: builder.mutation({
      query: (body) => {
        return {
          url: `accounttransfers`,
          method: 'POST',
          body
        };
      },
      invalidatesTags:["Loan","ClientLoans","ShareAccount","SavingsAccount"]
    }),

    getAccountTransferTemplate: builder.query({
      query: (clientId) =>
        `accounttransfers/template?fromClientId=${clientId}&fromAccountType=2&toAccountType=1`
    }),

    getSavingsToSavingsTransferTemplate: builder.query({
      query: ({clientId,fromAccountId}) =>
        `accounttransfers/template?fromClientId=${clientId}&fromAccountType=2&toAccountType=2&fromAccountId=${fromAccountId}`
    }),

    getAccountRefundByTransferTemplate: builder.query({
      query: (params) =>{
        return {
          url: `accounttransfers/templateRefundByTransfer`,
          method:'GET',
          params
        }
      }
    }),

    //Standing Instructions
    getStandingInstructions: builder.query({
      query: (params) => {
        return {
          url: `standinginstructions`,
          method:"GET",
          params
        }
      },
      providesTags:["StandingInstructions"]
    }),
    
    //Standing Instruction Run History
    getStandingInstructionsRunHistory: builder.query({
      query: () => `standinginstructionrunhistory?limit=300&sortBy=executionTime&sortOrder=DESC`,
      providesTags:["StandingInstructions"]
    }),
    getStandingInstructionsRunHistoryForClient: builder.query({
      query: (clientId) => `standinginstructionrunhistory?clientId=${clientId}`,
      providesTags:["StandingInstructions"]
    }),
    createStandingInstuctions: builder.mutation({
      query: body =>{
        return {
          url: `standinginstructions`,
          method:"POST",
          body
        }
      },
      invalidatesTags:["StandingInstructions"]
    }),

    deleteStandingInstruction: builder.mutation({
      query: id => {
        return {
          url: `standinginstructions/${id}?command=delete`,
          method:"PUT",
        }
      },
      invalidatesTags:["StandingInstructions"]
    }),

    getStandingInstructionsTemplate: builder.query({
      query: params =>{
        return {
          url: `standinginstructions/template`,
          params
        }
      }
    }),

    updateStandingInstruction: builder.mutation({
      query: body =>{
        const {id,...patch} = body;
        return {
          url: `standinginstructions/${id}?command=update`,
          method:"PUT",
          body:patch
        }
      }
    }),

    getFunds: builder.query({
      query: () => `funds`,
      providesTags:["Funds"]
    }),
    createFund: builder.mutation({
      query: (body) => {
        return {
          url:`funds`,
          method:'POST',
          body
        }
      },
      invalidatesTags:["Funds"]
    }),
    updateFund: builder.mutation({
      query: (body) => {
        const {id,...rest} = body
        return {
          url:`funds/${id}`,
          method:'PUT',
          body:rest
        }
      },
      invalidatesTags:["Funds"]
    }),
    //loan Products
    getLoanProducts: builder.query({
      query: () => `loanproducts`,
      providesTags:["LoanProducts"]
    }),
    retrieveLoanProduct: builder.query({
      query: (id) => `loanproducts/${id}`
    }),
    getLoanProductTemplate: builder.query({
      query: (id) => `loanproducts/${id}?template=true`
    }),
    createLoanProduct: builder.mutation({
      query: (body) => {
        return { url: `loanproducts`, method: 'POST', body };
      },
      invalidatesTags:["LoanProducts"]
    }),
    updateLoanProduct: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return { url: `loanproducts/${id}`, method: 'PUT', body: { ...patch } };
      },
      invalidatesTags:["LoanProducts"]
    }),
    getLoanProductMixes: builder.query({
      query: () =>({
        url:"loanproducts",
        params:{
          associations:"productMixes"
        }
      }),
      providesTags:["LoanProductMix"]
    }),
    getLoanProductMixTemplate: builder.query({
      query: ()=>({
        url:"loanproducts/template",
        params:{isProductMixTemplate:true}
      }),
      providesTags:["LoanProductMixTemplate"]
    }),

    getLoanProductMixTemplateForAProduct: builder.query({
      query: (id)=>({
        url:`loanproducts/${id}/productmix`,
        params:{template:true}
      })
    }),

    createLoanProductMix: builder.mutation({
      query: (body) =>{
        const {id,...rest} = body
        return {
          url:`loanproducts/${id}/productmix`,
          method:"POST",
          body:rest
        }
      },
      invalidatesTags:["LoanProductMix","LoanProductMixTemplate"]
    }),
    updateLoanProductMix: builder.mutation({
      query: (body) =>{
        const {id,...rest} = body
        return {
          url:`loanproducts/${id}/productmix`,
          method:"PUT",
          body:rest
        }
      },
      invalidatesTags:["LoanProductMix","LoanProductMixTemplate"]
    }),
    deleteLoanProductMix: builder.mutation({
      query: (id) =>({
          url:`loanproducts/${id}/productmix`,
          method:"DELETE",
        })
      ,
      invalidatesTags:["LoanProductMix","LoanProductMixTemplate"]
    }),
    //Loans

    getLoans: builder.query({
      query: () => `loans`
    }),
    getLoansForApproval: builder.query({
      query: () => `loans?sqlSearch=l.loan_status_id+in+(100)&status=100&limit=0`,
      providesTags:["LoansForApproval"]
    }),
    getLoansPendingReschedule: builder.query({
      query: () => `rescheduleloans?command=pending`,
      providesTags:["LoansForReschedule"]
    }),
    actionPendingReschedule: builder.mutation({
      query: ({id,action,body}) => ({url:`rescheduleloans/${id}?command=${action}`,method:"POST",body}),
      invalidatesTags:["LoansForReschedule","Loan"]
    }),
    getLoansForDisbursal: builder.query({
      query: () => `loans?sqlSearch=l.loan_status_id+in+(200)&status=200&limit=0`,
      providesTags:["LoansForDisbursal"]
    }),
    getActiveLoans: builder.query({
      query: () => `loans?sqlSearch=l.loan_status_id+in+(300)&status=300&limit=0`,
      providesTags:["ActiveLoans"],
      
    }),
    getOverdueLoans: builder.query({
      query: () => `loans?sqlSearch=l.id+in+(select loan_id from m_loan_arrears_aging)`
    }),
    getClientOverdueLoans: builder.query({
      query: (clientId) => `loans?sqlSearch=l.loan_status_id+in+(300)+AND+l.client_id=${clientId}`
    }),
    getOverdueNPALoans: builder.query({
      query: () => `loans?sqlSearch=l.is_npa=TRUE`,
      providesTags:["NPALoans"]
    }),
    getClientLoans: builder.query({
      query: (clientId) =>
        `clients/${clientId}/accounts?fields=loanAccounts&limit=-1`,
      providesTags:['ClientLoans']
    }),
    getClientSavings: builder.query({
      query: (clientId) =>
        `clients/${clientId}/accounts?fields=savingsAccounts&limit=-1`,
      providesTags:['ClientSavings']
    }),
    getClientGuarantors: builder.query({
      query: (clientId) =>
        `clients/${clientId}/accounts?fields=guarantorAccounts&limit=-1`,
      providesTags:['GuarantorAccounts']
    }),
    getSavingsChargeTemplate: builder.query({
      query: (accountId) => `savingsaccounts/${accountId}/charges/template`
    }),
    getClientShares: builder.query({
      query: (clientId) =>
        `clients/${clientId}/accounts?fields=shareAccounts&limit=-1`,
      providesTags:['ClientShares']
    }),
    getNewLoanTemplate: builder.query({
      query: (params) =>{
        return { url:`loans/template`,params}
      }
    }),

    getExistingLoanTemplate: builder.query({
      query: (id) => `loans/${id}?template=true`
    }),

    createLoan: builder.mutation({
      query: (body) => {
        return { url: `loans`, method: 'POST', body };
      }
    }),
    addLoanNote: builder.mutation({
      query: (body) => {
        const { loanId, ...rest } = body;
        return {
          url: `loans/${loanId}/notes`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    updateLoan: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return { url: `loans/${id}`, method: 'PUT', body: patch };
      }
    }),

    getLoanDetails: builder.query({
      query: (id) => `loans/${id}/?associations=all`,
      providesTags:["Loan"]
    }),
    calculateLoanSchedule: builder.mutation({
      query: (body) => {
        return {
          url: `loans?command=calculateLoanSchedule`,
          body,
          method: 'POST'
        };
      }
    }),
    approveLoan: builder.mutation({
      query: (body) => {
        const { id, ...approvalDetails } = body;
        return {
          url: `loans/${id}?command=approve`,
          body: approvalDetails,
          method: 'POST'
        };
      },
      invalidatesTags:["Loan","LoansForApproval"]
    }),
    approveLoanNoRefetch: builder.mutation({
      query: (body) => {
        const { id, ...approvalDetails } = body;
        return {
          url: `loans/${id}?command=approve`,
          body: approvalDetails,
          method: 'POST'
        };
      },
      
    }),
    addLoanCollateral: builder.mutation({
      query: body =>{
        const {id,...rest} = body;
        return {
          url: `loans/${id}/collaterals`,
          method: "POST",
          body: rest
        };
      },
      invalidatesTags:["LoanCollaterals"]
    }),
    updateLoanCollateral: builder.mutation({
      query: body =>{
        
        const {loanId,collateralId,...rest} = body;
        return {
          url: `loans/${loanId}/collaterals/${collateralId}`,
          method: "PUT",
          body: rest
        };
      },
      invalidatesTags:["LoanCollaterals"]
    }),
    deleteLoanCollateral: builder.mutation({
      query: body =>{
        const {loanId,collateralId} = body;
        return {
          url: `loans/${loanId}/collaterals/${collateralId}`,
          method: "DELETE",
          body: rest
        };
      },
      invalidatesTags:["LoanCollaterals"]
    }),

    getLoanCollateral: builder.query({
      query: id =>{
        return {
          url: `loans/${id}/collaterals`,
        };
      },
      providesTags:["LoanCollaterals"]
    }),
    getLoanCollateralTemplate: builder.query({
      query: id =>{
        return {
          url: `loans/${id}/collaterals/template`,
        };
      },
      providesTags:["LoanCollaterals"]
    }),

    undoApproval: builder.mutation({
      query: (body) => {
        const { id, note } = body;
        return {
          url: `loans/${id}?command=undoApproval`,
          body: { note },
          method: 'POST'
        };
      },
      invalidatesTags:["Loan","LoansForApproval"]
    }),

    undoDisbursal: builder.mutation({
      query: (body) => {
        const { id, note } = body;
        return {
          url: `loans/${id}?command=undoDisbursal`,
          body: { note },
          method: 'POST'
        };
      },
      invalidatesTags:["Loan"]
    }),
    assignLoanOfficer: builder.mutation({
      query: (body) => {
        
        const { id, ...rest } = body;
        return {
          url: `loans/${id}?command=assignLoanOfficer`,
          body: rest,
          method: 'POST'
        };
      },
      invalidatesTags:["Loan"]
    }),
    unAssignLoanOfficer: builder.mutation({
      query: (body) => {

        const { id, toLoanOfficerId } = body;
        return {
          url: `loans/${id}?command=unassignLoanOfficer`,
          body: {toLoanOfficerId},
          method: 'POST'
        };
      },
      invalidatesTags:["Loan"]
    }),
    rejectLoanApplication: builder.mutation({
      query: (body) => {
        const { id, ...rejectDetails } = body;
        return {
          url: `loans/${id}?command=reject`,
          body: rejectDetails,
          method: 'POST'
        };
      },
      invalidatesTags:["Loan","ClientLoans"]
    }),
    withdrawLoanApplication: builder.mutation({
      query: (body) => {
        const { id, ...withdrawalDetails } = body;
        return {
          url: `loans/${id}?command=withdrawnByApplicant`,
          body: withdrawalDetails,
          method: 'POST'
        };
      },
      invalidatesTags:["Loan"]
    }),
    disburseLoan: builder.mutation({
      query: (body) => {
        const { id, ...disbursalDetails } = body;
        return {
          url: `loans/${id}?command=disburse`,
          body: disbursalDetails,
          method: 'POST'
        };
      },
      invalidatesTags:["Loan","LoansForDisbursal"]
    }),
    disburseLoanNoRefetch: builder.mutation({
      query: (body) => {
        const { id, ...disbursalDetails } = body;
        return {
          url: `loans/${id}?command=disburse`,
          body: disbursalDetails,
          method: 'POST'
        };
      },
      
    }),

    disburseLoanToSavings: builder.mutation({
      query: (body) => {
        const { id, ...disbursalDetails } = body;
        return {
          url: `loans/${id}?command=disbursetosavings`,
          body: disbursalDetails,
          method: 'POST'
        };
      },
      invalidatesTags:["Loan"]
    }),
    rescheduleLoan: builder.mutation({
      query: body =>{
        return {
          url:`rescheduleloans`,
          method:"POST",
          body
        }
      },
      invalidatesTags:["LoansForReschedule"]
    }),
    getLoanChargeTemplate: builder.query({
      query: (loanId) => `loans/${loanId}/charges/template`
    }),
    getLoanTransactionsTemplate: builder.query({
      query: ({loanId,command}) =>
        `loans/${loanId}/transactions/template?command=${command}`,
      providesTags:["LoanPaymentTemplate"]
    }),

    adjustLoanTransaction: builder.mutation({
      query: ({loanId,transactionId,...rest}) =>({
        url:`loans/${loanId}/transactions/${transactionId}`,
        method:"POST",
        body:{...rest}
        
      }
        
        ),
      invalidatesTags:["Loan"]
    }),

    undoLoanTransaction: builder.mutation({
      query: (body) => {
        const { loanId,transactionId, ...rest } = body;
        return {
          url: `loans/${loanId}/transactions/${transactionId}?command=undo`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan","LoanPaymentTemplate"]
    }),

    repayLoan: builder.mutation({
      query: (body) => {
        const { id,command, ...rest } = body;
        return {
          url: `loans/${id}/transactions?command=${command}`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan","LoanPaymentTemplate"]
    }),
    repayLoanNoRefetch: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `loans/${id}/transactions?command=repayment`,
          method: 'POST',
          body: rest
        };
      },
    }),
    writeOffLoan: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `loans/${id}/transactions?command=writeoff`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    undoWriteOffLoan: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `loans/${id}/transactions?command=undowriteoff`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    assignStaffLoan: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `loans/${id}?command=assignStaff`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    assignStaffClient: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `clients/${id}?command=assignStaff`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Client"]
    }),
    waiveLoanIntrest: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `loans/${id}/transactions?command=waiveInterest`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    createLoanCharge: builder.mutation({
      query: (body) => {
        const { accountId, ...rest } = body;
        return {
          url: `loans/${accountId}/charges`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    payLoanCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId,transactionDate,dateFormat,locale, ...rest } = body;
        return {
          url: `loans/${accountId}/charges/${chargeId}?command=pay`,
          method: 'POST',
          body: {transactionDate,dateFormat,locale}
        };
      },
      invalidatesTags:["Loan"]
    }),
    waiveLoanCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId,...rest } = body;
        return {
          url: `loans/${accountId}/charges/${chargeId}?command=waive`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    deleteLoanCharge: builder.mutation({
      query: (body) => {
        const { accountId, chargeId } = body;
        return {
          url: `loans/${accountId}/charges/${chargeId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags:["Loan"]
    }),
    createLoanGuarantor: builder.mutation({
      query: (body) => {
        const { loanId, ...rest } = body;
        return {
          url: `loans/${loanId}/guarantors`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["Loan"]
    }),
    getClientGuarantorAccountsTemplate: builder.query({
      query: ({loanId,clientId}) => `loans/${loanId}/guarantors/accounts/template?clientId=${clientId}`
    }),
    getLoanStaffTemplate: builder.query({
      query: id => `loans/${id}?fields=id,loanOfficerId,loanOfficerOptions&staffInSelectedOfficeOnly=true&template=true`
    }),
    //clients
    
    uploadClientImage: builder.mutation({
      query: (data)=>{
        const {clientId,photo} = data;
        return {
          method:"POST",
          url: `clients/${clientId}/images`,
          body:photo
        };
      },
      invalidatesTags:["ClientImages"]
    }),
    getClients: builder.query({
      query: () => `clients?limit=0`,
      providesTags:["Clients"]
    }),
    getClient: builder.query({
      query: id => `clients/${id}`,
      providesTags:["Client"]
    }),
    createClient: builder.mutation({
      query: (body) => {
        return { url: `clients`, method: 'POST', body };
      },
      invalidatesTags:["Clients"]
    }),
    createClientNoRefetch: builder.mutation({
      query: (body) => {
        return { url: `clients`, method: 'POST', body };
      },
    }),
    updateClient: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return { url: `clients/${id}`, method: 'PUT', body: patch };
      },
      invalidatesTags:["Client","Clients"]
    }),
    getCloseClientTemplate: builder.query({
      query: () => `clients/template?commandParam=close`
    }),
    closeClient: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return {
          url: `clients/${id}?command=close`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["Client"]
    }),
    reactivateClient: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return {
          url: `clients/${id}?command=reactivate`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["Client"]
    }),

    changeClientStatus: builder.mutation({
      query: (body) => {
        const { id,command, ...patch } = body;
        return {
          url: `clients/${id}?command=${command}`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["Client","Clients"]
    }),
    proposeClientTransfer: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return {
          url: `clients/${id}?command=proposeTransfer`,
          method: 'POST',
          body: patch
        };
      }
    }),
    acceptClientTransfer: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return {
          url: `clients/${id}?command=acceptTransfer`,
          method: 'POST',
          body: patch
        };
      },
      invalidatesTags:["Client"]
    }),
    getClient: builder.query({
      query: (id) => `clients/${id}`,
      providesTags:["Client"]
    }),
    getClientTemplate: builder.query({
      query: (id) => `clients/${id}?staffInSelectedOfficeOnly=true&template=true`,
      invalidatesTags:["Client"]
    }),
    getClientsTemplate: builder.query({
      query: () => `clients/template?staffInSelectedOfficeOnly`
    }),
    getStaffInSelectedBranch: builder.query({
      query: (officeId) => `clients/template?officeId=${officeId}&staffInSelectedOfficeOnly`
    }),
    getClientAccounts: builder.query({
      query: (id) => `clients/${id}/accounts`,
      keepUnusedDataFor: 5
    }),

    getClientAddresses: builder.query({
      query: (id) => `client/${id}/addresses`,
      providesTags:["ClientAddresses"]
    }),
    createClientAddress: builder.mutation({
      query: (body) => {
        const { clientId, addressTypeId, ...rest } = body;
        return {
          url: `client/${clientId}/addresses?type=${addressTypeId}`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["ClientAddresses"]
    }),
    getClientIdentifiers: builder.query({
      query: (id) => `clients/${id}/identifiers`,
      providesTags:["ClientIdentities"]
    }),
    createClientIdentifier: builder.mutation({
      query: (body) => {
        const {clientId,...rest} = body;
        return {url:`clients/${clientId}/identifiers`,body:rest,method:'POST'}
        
      },
      invalidatesTags:["ClientIdentities"]
    }),
    getClientImages: builder.query({
      query: (id) => {
        return {
          url:`clients/${id}/images`,
          responseHandler: res=> res.status==200? res.text():res
        }
        },
      providesTags:["ClientImages"]
    }),
    getCodes: builder.query({
      query: () => `codes`
    }),
    createCode: builder.mutation({
      query: body =>{
        return {
          url:`codes`,
          body,
          method:"POST"
        }
      }
    }),
    updateCode: builder.mutation({
      query: body =>{
        const {id,...rest} = body
        return {
          url:`codes/${id}`,
          body:rest,
          method:"PUT"
        }
      }
    }),
    getCode: builder.query({
      query: (id) => `codes/${id}`
    }),
    getCodeValues: builder.query({
      query: (id) => `codes/${id}/codevalues`,
      providesTags:["CodeValues"]
    }),
    createCodeValue: builder.mutation({
      query: (body) => {
        const { codeId, ...rest } = body;
        return {
          url: `codes/${codeId}/codevalues`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["CodeValues"]
    }),

    getProvisioningEntriesList: builder.query({
      query: () => `provisioningentries`,
      providesTags:["ProvisioningEntries"]
    }),

    createProvisioningEntry: builder.mutation({
      query: (body) => {
        return{url:`provisioningentries`,body,method:"POST"}
        },
      invalidatesTags:["ProvisioningEntries"]
    }),

    reCreateProvisioningEntry: builder.mutation({
      query: (id) => {
        return{
          url:`provisioningentries/${id}`,
          body:{command:"recreateprovisioningentry"},
          method:"POST",
          params:{command:"recreateprovisioningentry"}
        }
        },
      invalidatesTags:["ProvisioningEntryDetails","ProvisioningEntryItems"]
    }),

    getProvisioningEntryDetail: builder.query({
      
      query: (id) => `provisioningentries/${id}`,
      providesTags:["ProvisioningEntryDetails"]
    }),

    getProvisioningEntryItems: builder.query({
      query: (id) => {
        return{url:`provisioningentries/entries?id=${id}`,params:{limit:20,offset:0}}
        },
      providesTags:["ProvisioningEntryItems"]
    }),

    updateCodeValue: builder.mutation({
      query: (body) => {
        const { codeId, valueId, ...rest } = body;
        return {
          url: `codes/${codeId}/codevalues/${valueId}`,
          method: 'PUT',
          body: rest
        };
      },
      invalidatesTags:["CodeValues"]
    }),
    getUsers: builder.query({
      query: () => `users`,
      providesTags:["Users"]
    }),
    getUser: builder.query({
      query: (id) => `users/${id}`
    }),
    getUserTemplate: builder.query({
      query: (id) => `users/${id}?template=true`
    }),
    createUser: builder.mutation({
      query: (body) => {
        return { url: `users`, method: 'POST', body };
      },
      invalidatesTags:["Users"]
    }),
    deleteUser: builder.mutation({
      query: (id) => {
        return { url: `users/${id}`, method: 'DELETE' };
      },
      invalidatesTags:["Users"]
    }),
    updateUser: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `users/${id}`, method: 'PUT', body: rest };
      }
    }),
    getUserDetails: builder.query({
      query: (id) => `users/${id}`
    }),
    getGroups: builder.query({
      query: () => `groups`,
      providesTags:["Groups"]
    }),
    getGroupDetails: builder.query({
      query: (id) => `groups/${id}?associations=all`,
      providesTags:["Group"]
    }),
    getGroupAccounts: builder.query({
      query: id => `groups/${id}/accounts`,
      providesTags:["GroupAccounts"]
    }),
    associateGroupMembers: builder.mutation({
      query: body =>{
        const {id,clientMembers} = body;
        return{ url: `groups/${id}?command=associateClients`,body:{clientMembers},method:"POST"}
      },
      invalidatesTags:["Group"]
    }),
    disassociateGroupMembers: builder.mutation({
      query: body =>{
        const {id,clientMembers} = body;
        return{ url: `groups/${id}?command=disassociateClients`,body:{clientMembers},method:"POST"}
      },
      invalidatesTags:["Group"]
    }),
    getGroupTemplate: builder.query({
      query: id => `groups/${id}?associations=all&template=true`
    }),
    assignGroupRole: builder.mutation({
      query: body =>{
        const {id, role, clientId} = body;
        return {
          url: `groups/${id}?command=assignRole`,
          method: "POST",
          body:{clientId,role}
        }
      }
    }),
    unassignGroupRole: builder.mutation({
      query: body =>{
        const {id, role, clientId} = body;
        return {
          url: `groups/${id}?command=unassignRole&roleId=role`,
          method: "POST",
        }
      }
    }),
    createGroup: builder.mutation({
      query: body =>{
        
        return {
          url: `groups`,
          body,
          method:"POST"
        }
      },
      invalidatesTags:["Groups"]
    }),
    updateGroup: builder.mutation({
      query: body =>{
        const {id,...rest} = body;
        return {
          url: `groups/${id}`,
          body: rest,
          method:"PUT"
        }
      },
      invalidatesTags:["Groups"]
    }),

    getGroupMembers: builder.query({
      query: id => `groups/${id}`,
    }),

    getNotifications: builder.query({
      query: () => `notifications`,
      providesTags:["Notifications"]
    }),
    updateNotification: builder.mutation({
      query: (id) => {
        
        return { url: `notifications/${id}`, method: 'PUT' };
      }
    }),
    updateNotifications: builder.mutation({
      query: () =>{
        return{ url: `notifications`,method:'PUT'};
      },
      invalidatesTags:["Notifications"]
    }),
    // Accounting: Closures
    getClosures: builder.query({
      query: ()=>`glclosures`,
      providesTags:["Closures"]
    }), 
    createClosure: builder.mutation({
      query: body =>{
        return {
          url: `glclosures`,
          body,
          method:'POST'
        }
      },
      invalidatesTags:["Closures"]
    }),
    deleteClosure: builder.mutation({
      query: id =>{
        return {
          url: `glclosures/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags:["Closures"]
    }),
    // Accounting: Ledger Accounts
    getAccounts: builder.query({
      query: () => `glaccounts`,
      providesTags:["GLAccounts"]
    }),
    getPlainAccounts: builder.query({
      query: () => `glaccounts`,
      providesTags:["GLAccounts"]
    }),
    retrieveAccount: builder.query({
      query: (id) => `glaccounts/${id}`,
      providesTags:["GLAccount"]
    }),
    getAccountTemplate: builder.query({
      query: (id) => `glaccounts/${id}?template=true`,
    }),
    getManualEntryAccounts: builder.query({
      query: () => `glaccounts?disabled=false&manualEntriesAllowed=true&usage=1`
    }),
    getAccountEntries: builder.query({
      query: (args) => {return {url:`journalentries`,params:args}}
    }),
    reverseTransactions: builder.mutation({
      query: (transactionId) => ({
        url: `journalentries/${transactionId}?command=reverse`,
        body:{transactionId},
        method: 'POST'
      }),
      
    }),

    getAccount: builder.query({
      query: id => `glaccounts/${id}`,
      providesTags:["GLAccount"]
    }),

    createGLAccount: builder.mutation({
      query: (body) => {
        return { url: `glaccounts`, method: 'POST', body };
      },
      invalidatesTags:["GLAccounts"]
    }),
    createGLAccountNoRefetch: builder.mutation({
      query: (body) => {
        return { url: `glaccounts`, method: 'POST', body };
      },
    }),

    updateGLAccount: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return { url: `glaccounts/${id}`, method: 'PUT', body: patch };
      },
      invalidatesTags:["GLAccount","GLAccounts"]
    }),
    getTemplate: builder.query({
      query: (entity) => `${entity}/template`
    }),

    getRoles: builder.query({
      query: () => `roles`,
      providesTags:["Roles"]
    }),
    createRole: builder.mutation({
      query: (body) => {
        return { url: `roles`, method: 'POST', body };
      },
      invalidatesTags:["Roles"]
    }),
    updateRole: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `roles/${id}`, method: 'PUT', body: rest };
      }
    }),
    getRolePermissions: builder.query({
      query: (id) => `roles/${id}/permissions`,
      providesTags:["RolePermissions"]
    }),
    getMakerChecker: builder.query({
      query: () => { 
        return {
          url:`permissions`,
          params:{makerCheckerable:true}
        }},
        providesTags:["MakerChecker"]
    }),
    updateMakerChecker: builder.mutation({
      query: (body) => { 
        return {
          method:"PUT",
          url:`permissions`,
          body,
          params:{makerCheckerable:true}
        }},
        invalidatesTags:["MakerChecker"]
    }),
    getMakerCheckers: builder.query({
      query: () => `makercheckers?includeJson=true`,
      providesTags:["UserMakerCheckers"]
    }),
    approveMakerChecker: builder.mutation({
      query: (id) => {return {url:`makercheckers/${id}?command=approve`,method:"POST"}},
      invalidatesTags:["UserMakerCheckers"]
    }),
    rejectMakerChecker: builder.mutation({
      query: (id) => {return {url:`makercheckers/${id}?command=reject`,method:"POST"}},
      invalidatesTags:["UserMakerCheckers"]
    }),
    deleteMakerChecker: builder.mutation({
      query: (id) => {return {url:`makercheckers/${id}`,method:"DELETE"}},
      invalidatesTags:["UserMakerCheckers"]
    }),
    updateRolePermissions: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `roles/${id}/permissions`, method: 'PUT', body: rest };
      },
      invalidatesTags:["RolePermissions"]
    }),
    getConfigurations: builder.query({
      query: () => `configurations`,
      providesTags:["Configurations"]
    }),
    getConfigurationDetails: builder.query({
      query: (name) => `configurations/name/${name}`,
      providesTags:["ConfigurationDetail"]
    }),
    updateConfigurations: builder.mutation({
      query: (body) => {
        const {id,...rest} = body; 
        return {
          url:`configurations/${id}`,
          method:'PUT',
          body:rest
        };
      },
      invalidatesTags:["Configurations","ConfigurationDetail"]
    }),
    //Offices
    getOffices: builder.query({
      query: () => `offices`,
      providesTags:['Offices']
    }),
    getOfficeTemplate: builder.query({
      query: (id) => `offices/${id}?template=true`,
      
    }),
    retrieveOffice: builder.query({
      query: (id) => `offices/${id}`,
      providesTags:["Office"]
    }),
    createOffice: builder.mutation({
      query: (body) => ({ url: `offices`, method: `POST`, body }),
      invalidatesTags:["Offices"]
    }),
    updateOffice: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return {
          url: `offices/${id}`,
          method: `PUT`,
          body: patch
        };
      },
      invalidatesTags:["Office","Offices"]
    }),
    //tellers
    getTellers: builder.query({
      query: () => `tellers`,
      providesTags:["Tellers"]
    }),
    getTeller: builder.query({
      query: (id) => `tellers/${id}`
    }),
    createTeller: builder.mutation({
      query: (body) => { return { url: `tellers`, method: `POST`, body }},
      invalidatesTags:["Tellers"]
    }),
    updateTeller: builder.mutation({
      query: (body) => {
        
        const { id, ...patch } = body;
        
        return {
          url: `tellers/${id}`,
          method: `PUT`,
          body: patch
        };
      },
      invalidatesTags:["Tellers"]
    }),
    getTellerCashiers: builder.query({
      query: (tellerId) => `tellers/${tellerId}/cashiers`,
      providesTags:["Cashiers"]
    }),
    createTellerCashier: builder.mutation({
      query: (body) => {
        const { tellerId, ...rest } = body;
        return {
          url: `tellers/${tellerId}/cashiers`,
          body: rest,
          method: 'POST'
        };
      },
      invalidatesTags:["Cashiers"]
    }),
    updateTellerCashier: builder.mutation({
      query: (body) => {
        const { tellerId, cashierId, ...rest } = body;
        return {
          url: `tellers/${tellerId}/cashiers/${cashierId}`,
          body: rest,
          method: 'PUT'
        };
      },
      invalidatesTags:["Cashier","Cashiers"]
    }),
    getTellerCashier: builder.query({
      query: (params) => {
        const { id, cashierId } = params;
        return { url: `tellers/${id}/cashiers/${cashierId}` };
      },
      providesTags:["Cashier"]
    }),
    getTellerCashierTransactions: builder.query({
      query: (params) => {
        const { id, cashierId } = params;
        return { url: `tellers/${id}/cashiers/${cashierId}/transactions` };
      },
      providesTags:["CashierTransactions"]
    }),
    allocatedCashToCashier: builder.mutation({
      query: (params) => {
        const { id, cashierId, ...rest } = params;
        return {
          url: `tellers/${id}/cashiers/${cashierId}/allocate`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["CashierTransactions","CashierSummary"]
    }),
    settleCashier: builder.mutation({
      query: (params) => {
        const { id, cashierId, ...rest } = params;
        return {
          url: `tellers/${id}/cashiers/${cashierId}/settle`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["CashierTransactions","CashierSummary"]
    }),
    getTellerCashierSummaryTransactions: builder.query({
      query: (params) => {
        const { id, cashierId, currencyCode,fromDate,toDate,locale,dateFormat } = params;
        return {
          url: `tellers/${id}/cashiers/${cashierId}/summaryandtransactions`,
          params:{currencyCode,fromDate,toDate,locale,dateFormat}
        };
      },
      providesTags:["CashierSummary"],
    }),
    getBranchCashierSummaryTransactions: builder.query({
      query: (params) => {
        const { id, currencyCode,fromDate,toDate,locale,dateFormat } = params;
        return {
          url: `tellers/${id}/summaryandtransactions`,
          params:{currencyCode,fromDate,toDate,locale,dateFormat}
        };
      },
      
    }),
    getCurrencies: builder.query({
      query: () => `currencies`
    }),
    getWorkingDays: builder.query({
      query: () => `workingdays`,
      providesTags:["WorkingDays"]
    }),
    
    updateWorkingDays: builder.mutation({
      query: body =>{
        return {
          url: `workingdays`,
          method:"PUT",
          body
        }
      },
      invalidatesTags:["WorkingDays"]
    }),
    getHolidays: builder.query({
      query: () =>`holidays/?officeId=1`,
      providesTags:["Holidays"]
    }),
  
    createHoliday: builder.mutation({
      query: body =>{
        return {
          url:`holidays`,
          method:"POST",
          body
        }
      },
      invalidatesTags:["Holidays"]
    }),
    activateHoliday: builder.mutation({
      query: id =>{
        return {
          url: `holidays/${id}?command=activate`,
          method:"POST"
        }
      },
      invalidatesTags:["Holidays"]
    }),
    updateHoliday: builder.mutation({
      query: body =>{
        const {id,...rest} = body
        return {
          url: `holidays/${id}`,
          method:"PUT",
          body:rest
        }
      },
      invalidatesTags:["Holidays"]
    }),
    deleteHoliday: builder.mutation({
      query: id =>{
        
        return {
          url: `holidays/${id}`,
          method:"DELETE",
        }
      },
      invalidatesTags:["Holidays"]
    }),
    updateCurrencies: builder.mutation({
      query: (body) => ({ url: `currencies`, body, method: 'PUT' })
    }),
    //payment types
    getPaymentTypes: builder.query({
      query: () => `paymenttypes`,
      providesTags:["PaymentTypes"]
    }),
    createPaymentType: builder.mutation({
      query: (body) => {
        return { url: `paymenttypes`, method: 'POST', body };
      },
      invalidatesTags:["PaymentTypes"]
    }),
    updatePaymentType: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `paymenttypes/${id}`, method: 'PUT', body: rest };
      },
      invalidatesTags:["PaymentTypes"]
    }),

    getDatatables: builder.query({
      query: () => `datatables`,
      providesTags:["Datatables"]
    }),
    createDatatable: builder.mutation({
      query: body =>{
        return {
          url: `datatables`,
          method: "POST",
          body
        }
      },
      invalidatesTags:["Datatables"]
    }),
    updateDatatable: builder.mutation({
      query: body =>{
        const {id,...rest} = body
        return {
          url: `datatables/${id}`,
          method: "PUT",
          body:rest
        }
      },
      invalidatesTags:["Datatables"]
    }),
    getFinancialActivityAccounts: builder.query({
      query: () => `financialactivityaccounts`,
      providesTags:["FinancialActivityMappings"]
    }),
    getFinancialActivityAccountsTemplate: builder.query({
      query: () => `financialactivityaccounts/template`
    }),
    createFinancialActivityAccount: builder.mutation({
      query: (body) => {
        return { url: `financialactivityaccounts`, method: 'POST', body };
      },
      invalidatesTags:["FinancialActivityMappings"]
    }),
    updateFinancialActivityAccount: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `financialactivityaccounts/${id}`,
          method: 'PUT',
          body: rest
        };
      },
      invalidatesTags:["FinancialActivityMappings"]
    }),
    getFinancialActivityAccount: builder.query({
      query: (id) => `financialactivityaccounts/${id}`
    }),
    getStaff: builder.query({
      query: () => `staff?status=ALL`,
      providesTags:["Staff"]
    }),
    createStaff: builder.mutation({
      query: body =>{
        return {
          url:`staff`,
          method:"POST",
          body
        }
      },
      invalidatesTags:["Staff"]
    }),
    updateStaff: builder.mutation({
      query: body =>{
        const {id,...rest} = body;
        return {
          url:`staff/${id}`,
          method:"PUT",
          body:rest
        }
      },
      invalidatesTags:["Staff"]
    }),
    getOfficeStaff: builder.query({
      query: (officeId) => `staff?officeId=${officeId}&status=ALL`
    }),
    getStaffDetail: builder.query({
      query: id => `staff/${id}`
    }),
    getStaffEditTemplate: builder.query({
      query: id => `staff/${id}?status=all&template=true`
    }),
    //charges
    getCharges: builder.query({
      query: () => `charges`,
      invalidatesTags:["Charges"]
    }),
    //charges
    getChargeDetail: builder.query({
      query: (id) => `charges/${id}`
    }),
    createCharge: builder.mutation({
      query: (body) => ({ url: `charges`, method: `POST`, body }),
      invalidatesTags:["Charges"]
    }),
    deleteCharge: builder.mutation({
      query: (id) => ({ url: `charges/${id}`, method: `DELETE` }),
      invalidatesTags:["Charges"]
    }),
    updateCharge: builder.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `charges/${id}`, method: `PUT`, body };
      },
      invalidatesTags:["Charges"]
    }),

    // savings products
    getSavingsProductTemplate: builder.query({
      query: (id) => `savingsproducts/${id}?template=true`
    }),
    getSavingsProducts: builder.query({
      query: () => `savingsproducts`
    }),
    retrieveSavingsProduct: builder.query({
      query: (id) => `savingsproducts/${id}`
    }),
    createSavingsProduct: builder.mutation({
      query: (body) => ({ url: `savingsproducts`, method: `POST`, body })
    }),
    updateSavingsProduct: builder.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `savingsproducts/${id}`, method: `PUT`, body };
      }
    }),
    // share products
    getSharesProductTemplate: builder.query({
      query: (id) => `products/share/${id}?template=true`
    }),
    // share products
    getSharesProduct: builder.query({
      query: (id) => `products/share/${id}`
    }),
    getSharesProducts: builder.query({
      query: () => `products/share`
    }),

    createSharesProduct: builder.mutation({
      query: (body) => ({ url: `products/share`, method: `POST`, body })
    }),
    approveDividend: builder.mutation({
      query: (arg) => {
        const { dividendId, productId } = arg;
        return {
          url: `shareproduct/${productId}/dividend/${dividendId}?command=approve`,
          method: 'PUT'
        };
      }
    }),
    getDividendDetails: builder.query({
      query: (arg) => {
        const { dividendId, productId } = arg;
        return {
          url: `shareproduct/${productId}/dividend/${dividendId}`,
          method: 'GET'
        };
      }
    }),
    updateSharesProduct: builder.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `products/share/${id}`, method: `PUT`, body };
      }
    }),
    getShareProduct: builder.query({
      query: (id) => `products/share/${id}`
    }),
    getShareProductDividends: builder.query({
      query: (id) => `shareproduct/${id}/dividend`,
      providesTags: ["ShareDividends"]
    }),
    getProductDividendDetail: builder.query({
      query: ({productId,dividendId}) =>`shareproduct/${productId}/dividend/${dividendId}?dateFormat=dd MMMM yyyy&limit=10&locale=en&offset=0`
    }),
    initiateShareProductDividends: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `shareproduct/${id}/dividend`,
          method: 'POST',
          body: rest
        };
      },
      invalidatesTags:["ShareDividends"]
    }),

    //share accounts
    getShareAccountTemplate: builder.query({
      query: (id) => `accounts/share/template?clientId=${id}`
    }),
    createShareAccount: builder.mutation({
      query: (body) => ({ url: `accounts/share`, method: `POST`, body:{...body,useSavings:true} }),
      invalidatesTags:["ShareAccounts"]
    }),
    createShareAccountNoRefetch: builder.mutation({
      query: (body) => ({ url: `accounts/share`, method: `POST`, body:{...body,useSavings:true} }),
      
    }),
    createShareAccountWithSavings: builder.mutation({
      query: (body) => ({ url: `accounts/share`, method: `POST`, body:{...body,useSavings:true}}),
      invalidatesTags:["ShareAccounts"]
    }),
    approveShareAccount: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=approve`,
          method: `POST`,
          body: rest
        };
      },
      invalidatesTags:["ShareAccount","ClientShares"]
    }),
    approveShareAccountNoRefetch: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=approve`,
          method: `POST`,
          body: rest
        };
      },
      
    }),
    undoApproveShareAccount: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=undoApproval`,
          method: `POST`,
          body: rest
        };
      }
    }),
    rejectShareAccount: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=reject`,
          method: `POST`,
          body: rest
        };
      },
      invalidatesTags:["ShareAccount","ClientShares"]
    }),
    activateShareAccount: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=activate`,
          method: `POST`,
          body: rest
        };
      },
      invalidatesTags:["ShareAccount","ClientShares"]
    }),
    activateShareAccountNoRefetch: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=activate`,
          method: `POST`,
          body: rest
        };
      },
      
    }),
    retrieveShareAccount: builder.query({
      query: (id) => `accounts/share/${id}`,
      providesTags:["ShareAccount"]
    }),
    closeShareAccount: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `accounts/share/${id}?command=close`,
          method: `POST`,
          body: {...rest}
        };
      },
      invalidatesTags:["ShareAccount","ShareAccounts"]
    }),
    getShareAccounts: builder.query({
      query: () => `accounts/share`,
      providesTags:["ShareAccounts"]
    }),
    getShareAccount: builder.query({
      query: (id) => `accounts/share/${id}`,
      providesTags:["ShareAccount"]
    }),
    applyForShares: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=applyadditionalshares`,
          body: {...rest},
          method: 'POST',
        };
      },
      invalidatesTags:["ShareAccount"]
    }),
    applyForSharesNoRefetch: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=applyadditionalshares`,
          body: {...rest,useSavings:true},
          method: 'POST',
        };
      },
      
    }),
    applyForSharesWithSavings: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=applyadditionalshares`,
          body: {...rest,useSavings:true},
          method: 'POST',
        };
      },
      invalidatesTags:["ShareAccount"]
    }),
    rejectAdditionalShares: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=rejectadditionalshares`,
          body: rest,
          method: 'POST'
        };
      },
      invalidatesTags:["ShareAccount"]
    }),
    rejectAdditionalSharesNoRefetch: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=rejectadditionalshares`,
          body: rest,
          method: 'POST'
        };
      },
      
    }),
    approveAdditionalShares: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=approveadditionalshares`,
          body: rest,
          method: 'POST'
        };
      },
      invalidatesTags:["ShareAccount","Client"]
    }),
    approveAdditionalSharesNoRefetch: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=approveadditionalshares`,
          body: rest,
          method: 'POST'
        };
      },
      
    }),
    redeemShares: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=redeemshares`,
          body: rest,
          method: 'POST'
        };
      },
      invalidatesTags:["ShareAccount"]
    }),
    redeemSharesToSavings: builder.mutation({
      query: (body) => {
        const { shareAccountId, ...rest } = body;
        return {
          url: `accounts/share/${shareAccountId}?command=redeemshares`,
          body: {...rest,useSavings:true},
          method: 'POST'
        };
      },
      invalidatesTags:["ShareAccount"]
    }),

    // fixed deposit products
    getFixedDepositProducts: builder.query({
      query: () => `fixeddepositproducts`,
      providesTags:["FixedDepositProducts"]
    }),
    getFixedDepositProductTemplate: builder.query({
      query: (id) => `fixeddepositproducts/${id}?template=true`
    }),
    retrieveFixedDepositProduct: builder.query({
      query: (id) => `fixeddepositproducts/${id}`,
      providesTags:["FixedDepositProduct"]
    }),
    createFixedDepositProduct: builder.mutation({
      query: (body) => {
        return { url: `fixeddepositproducts`, method: 'POST', body };
      },
      invalidatesTags:["FixedDepositProducts"]
    }),

    updateFixedDepositProduct: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `fixeddepositproducts/${id}`, method: 'PUT', body: rest };
      },
      invalidatesTags:["FixedDepositProducts","FixedDepositProduct"]
    }),
    getFixedDepositAccountTemplate: builder.query({
      query: (clientId) => `fixeddepositaccounts/template?clientId=${clientId}`
    }),
    getFixedDepositAccountProductTemplate: builder.query({
      query: ({clientId,productId}) => `fixeddepositaccounts/template?clientId=${clientId}&productId=${productId}`
    }),
    createFDAccount: builder.mutation({
      query: (body) => ({ url: `fixeddepositaccounts`, method: `POST`, body }),
      invalidatesTags: ["ClientSavings","FixedDepositAccounts"]
    }),

    // recurring deposits
    getRecurringDepositProducts: builder.query({
      query: () => `recurringdepositproducts`,
      invalidatesTags:["RecurringDepositProducts"]
    }),
    getRecurringDepositProductTemplate: builder.query({
      query: (id) => `recurringdepositproducts/${id}?template=true`
    }),
    getRecurringDepositProductTemplate: builder.query({
      query: (id) => `recurringdepositproducts/${id}?template=true`
    }),
    retrieveRecurringDepositProduct: builder.query({
      query: (id) => `recurringdepositproducts/${id}`,
      providesTags:["RecurringDepositProduct"]
    }),
    createRecurringDepositProduct: builder.mutation({
      query: (body) => {
        return { url: `recurringdepositproducts`, method: 'POST', body };
      }
    }),

    updateRecurringDepositProduct: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return {
          url: `recurringdepositproducts/${id}`,
          method: 'PUT',
          body: rest
        };
      },
      invalidatesTags:["RecurringDepositProduct"]
    }),
    getRecurringDepositAccountTemplate: builder.query({
      query: (clientId) =>
        `recurringdepositaccounts/template?clientId=${clientId}`
    }),
    getRecurringDepositAccountProductTemplate: builder.query({
      query: ({clientId,productId}) =>
        `recurringdepositaccounts/template?clientId=${clientId}&productId=${productId}`
    }),
    
    createRecurringDepositAccount: builder.mutation({
      query: (body) => ({
        url: `recurringdepositaccounts`,
        method: `POST`,
        body
      }),
      invalidatesTags:["ClientSavings","RecurringDepositAccounts"]
    }),
    updateRecurringDepositAccount: builder.mutation({
      query: (body) => {
        const {accountId,...rest} = body
        return {
        url: `recurringdepositaccounts/${accountId}`,
        method: `PUT`,
        body:rest
      }
    },
      invalidatesTags:["ClientSavings","SavingsAccount"]
    }),
    createRecurringDepositAccount: builder.mutation({
      query: (body) => ({
        url: `recurringdepositaccounts`,
        method: `POST`,
        body
      }),
      invalidatesTags:["ClientSavings","RecurringDepositAccounts"]
    }),
    updateFixedDepositAccount: builder.mutation({
      query: (body) => {
        const {accountId,...rest} = body
        return {
        url: `fixeddepositaccounts/${accountId}`,
        method: `PUT`,
        body:rest
      }
    },
      invalidatesTags:["ClientSavings","SavingsAccount"]
    }),
    //audit trail
    getAudits: builder.query({
      query: (params) => {
        return { url:`audits`,params}
        
      }
    }),

    getAuditSearchTemplate: builder.query({
      query: () => `audits/searchtemplate`
    }),

    //journal entries
    getJournalEntries: builder.query({
      query: () => `journalentries`
    }),
    createTransaction: builder.mutation({
      query: (body) => {
        return { url: `journalentries`, method: 'POST', body };
      }
    }),

    //jobs
    getJobs: builder.query({
      query: () => `jobs`,
      providesTags:["Jobs"],
      keepUnusedDataFor:300

    }),
    runJob: builder.mutation({
      query: (id) => {
        return { url: `jobs/${id}?command=executeJob`, method: `POST` };
      },
      
    }),
    suspendScheduler: builder.mutation({
      query: () => {
        return { url: `scheduler?command=stop`, method: `POST` };
      },
      invalidatesTags:["SchedulerStatus"]
    }),
    activateScheduler: builder.mutation({
      query: () => {
        return { url: `scheduler?command=start`, method: `POST` };
      },
      invalidatesTags:["SchedulerStatus"]
    }),
    getSchedulerStatus: builder.query({
      query: () => {
        return { url: `scheduler` };
      },
      providesTags:["SchedulerStatus"]
    }),
    updateJob: builder.mutation({
      query: body =>{
        console.log(body);
        const {id,...patch} = body;
        return {
          url: `jobs/${id}`,
          method:"PUT",
          body:patch
        }
      },
      invalidatesTags:["Jobs"]
    }),

    //accounting rules
    getAccountingRules: builder.query({
      query: () => `accountingrules`,
      providesTags:["AccountingRules"]
    }),
    createAccountingRule: builder.mutation({
      query: (body) => {
        return {
          url: `accountingrules`,
          method: 'POST',
          body
        };
      },
      invalidatesTags:["AccountingRules"]
    }),
    updateAccountingRule: builder.mutation({
      query: (body) => {
        const { id, ...patch } = body;
        return {
          url: `accountingrules/${id}`,
          method: 'PUT',
          body: patch
        };
      },
      invalidatesTags:["AccountingRules"]
    }),

    deleteAccountingRule: builder.mutation({
      query: (id) => ({
        url: `accountingrules/${id}`,
        method: 'DELETE',
        
      }),
      invalidatesTags:["AccountingRules"]
    }),

    getReports: builder.query({
      query: () => `reports`,
      providesTags:["Reports"]
    }),
    createReport: builder.mutation({
      query: (body) => {
        return { url: `reports`, method: 'POST', body };
      },
      invalidatesTags:["Reports"]
    }),
    updateReport: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `reports/${id}`, method: 'PUT', body: rest };
      },
      invalidatesTags:["Reports","Report"]
    }),
    deleteReport: builder.mutation({
      query: (id) => {
        console.log(id);
        
        return { url: `reports/${id}`, method: 'DELETE' };
      },
      invalidatesTags:["Reports"]
    }),
    getReportDetail: builder.query({
      query: (id) => `reports/${id}`
    }),
    getReportParameterList: builder.query({
      query: (reportName) =>
        `runreports/FullParameterList?R_reportListing='${reportName}'&parameterType=true`
    }),
    getReportParameterValues: builder.query({
      query: (parameterName) => `runreports/${parameterName}?parameterType=true`
    }),
    getChildParameterValues: builder.query({
      query: (arg) => {
        const { parameterName, ...rest } = arg;
        return {
          url: `runreports/${parameterName}`,
          method: 'GET',
          params: { parameterType: true, ...rest }
        };
      }
    }),
    runPdfReport: builder.query({
      query: (reportAndArguments) => {
        const { reportName, ...args } = reportAndArguments;
        const isPdf = args['output-type']=="PDF";
        return {
          url: `runreports/${reportName}`,
          params: args,
          responseHandler: async(res) => isPdf?res.blob():res
        };
      },
      keepUnusedDataFor: 1
    }),
    runLoanTransactionReceipt: builder.query({
      query: transactionId =>{
        return {
          url: `/runreports/Loan%20Transaction%20Receipt?R_transactionId=${transactionId}&output-type=PDF`,
          responseHandler: async(res) => res.blob()
        }
      }    
    }),
    runSavingsTransactionReceipt: builder.query({
      query: transactionId =>{
        return {
          url: `/runreports/Savings%20Transaction%20Receipt?R_transactionId=${transactionId}&output-type=PDF`,
          responseHandler: async(res) => res.blob()
        }
      }    
    }),
    runReport: builder.query({
      query: (reportAndArguments) => {
        const { reportName, ...args } = reportAndArguments;
        return {
          url: `runreports/${reportName}`,
          params: args,
        };
      },
      keepUnusedDataFor: 1
    }),

    //logging in
    getLoginToken: builder.mutation({
      query: ({ username, password }) => {
        return {
          url: `authentication`,
          method: 'POST',
          body: { username, password }
        };
      }
    }),
    getExternalService: builder.query({
      query: (serviceName) => `externalservice/${serviceName}`,
      providesTags:["ExternalServicesConfigs"]
    }),
    updateExternalService: builder.mutation({
      query: body => {
        const {serviceName,...rest} = body
        return {url:`externalservice/${serviceName}`, body:rest, method:"PUT"} 
      },
      invalidatesTags:["ExternalServicesConfigs"]
    }),
    getSmsCampaigns: builder.query({
      query: () => `smscampaigns`,
      providesTags:["SMSCampaigns"]
    }),
    getSmsCampaignsTemplate: builder.query({
      query: () => `smscampaigns/template`,
      
    }),
    createSmsCampaign: builder.mutation({
      query: (body) => {
      return {url:`smscampaigns`,method:"POST",body}  
      },
      invalidatesTags:["SMSCampaigns"]
      
    }),
    activateSmsCampaign: builder.mutation({
      query: (body) => {
        const {id,...rest} = body
      return {url:`smscampaigns/${id}`,method:"POST",body:rest, params:{command:"activate"}}  
      },
      invalidatesTags:["SMSCampaigns"]
      
    }),
    updateSmsCampaign: builder.mutation({
      query: (body) => {
        const {id,...rest} = body
      return {url:`smscampaigns/${id}`,method:"PUT",body:rest}  
      },
      invalidatesTags:["SMSCampaigns"]
      
    }),
    deactivateSmsCampaign: builder.mutation({
      query: (body) => {
        const {id,...rest} = body
      return {url:`smscampaigns/${id}`,method:"POST",body:rest, params:{command:"close"}}  
      },
      invalidatesTags:["SMSCampaigns"]
      
    }),
    reactivateSmsCampaign: builder.mutation({
      query: (body) => {
        const {id,...rest} = body
      return {url:`smscampaigns/${id}`,method:"POST",body:rest, params:{command:"reactivate"}}  
      },
      invalidatesTags:["SMSCampaigns"]
      
    }),
    deleteSmsCampaign: builder.mutation({
      query: (id) => {
          return {url:`smscampaigns/${id}`,method:"DELETE"}  
      },
      invalidatesTags:["SMSCampaigns"]
    }),
    retrieveSmsCampaign: builder.query({
      query: (id) => {
          return {url:`smscampaigns/${id}`}  
      },
      
    })

  })
});

export const {
  useDisburseLoanNoRefetchMutation,
  useApproveLoanNoRefetchMutation,
  useRepayLoanNoRefetchMutation,
  useCreateGLAccountNoRefetchMutation,
  useCreateClientNoRefetchMutation,
  useRedeemSharesToSavingsMutation,
  useUndoLoanTransactionMutation,
  useModifyTransactionMutation,
  useLazyGetBranchCashierSummaryTransactionsQuery,
  useDeleteChargeMutation,
  useLazyGetCurrenciesQuery,
  useGetClientGuarantorsQuery,
  useLazyGetClientGuarantorsQuery,
  useLazyGetClientGuarantorAccountsTemplateQuery,
  useLazyGetSavingsToSavingsTransferTemplateQuery,
  useDeleteAccountingRuleMutation,
  useGetServerInfoQuery,
  useGetRecurringDepositAccountDetailsQuery,
  useAdjustLoanTransactionMutation,
  useUpdateFixedDepositAccountMutation,
  useUpdateRecurringDepositAccountMutation,
  useLazyGetSavingsAccountTemplateForEditingQuery,
  useGetSavingsAccountTemplateForEditingQuery,
  usePrematureCloseTermDepositAccountMutation,
  useUndoApprovalSavingsApplicationMutation,
  useCalculatePrematureTermDepositAccountAmountMutation,
  useCalculateTermDepositAccountInterestMutation,
  usePostTermDepositAccountInterestMutation,
  useRejectSavingsApplicationMutation,
  useLazyGetBusinessDateQuery,
  useGetCOBDateQuery,
  useRetrieveRecurringDepositProductQuery,
  useCreateRecurringDepositAccountMutation,
  useGetBusinessDateQuery,
  useSetBusinessDateMutation,
  useUndoWriteOffLoanMutation,
  useGetResourceNotesQuery,
  useLazyGetLoanTransactionsTemplateQuery,
  useCreateResourceNoteMutation,
  useUpdateResourceNoteMutation,
  useDeleteResourceNoteMutation,
  useGetDocumentsQuery,
  useGetDocumentAttachmentQuery,
  useGetLoansPendingRescheduleQuery,
  useGetSavingsToSavingsTransferTemplateQuery,
  useLazyGetDocumentAttachmentQuery,
  useCreateDocumentMutation,
  useLazyGetDocumentsQuery,
  useActivateSchedulerMutation,
  useSuspendSchedulerMutation,
  useGetSchedulerStatusQuery,
  useCreateLoanProductMixMutation,
  useUpdateLoanProductMixMutation,
  useRetrieveAccountQuery,
  useRetrieveSmsCampaignQuery,
  useReCreateProvisioningEntryMutation,
  useCreateProvisioningEntryMutation,
  useGetProvisioningEntryItemsQuery,
  useActionPendingRescheduleMutation,
  useLazyGetProvisioningEntryItemsQuery,
  useLazyGetProvisioningEntryDetailQuery,
  useGetProvisioningEntriesListQuery,
  useGetProvisioningEntryDetailQuery,
  useLazyRetrieveProvisioningCriteriaTemplateQuery,
  useCreateProvisioningCriteriaMutation,
  useUpdateProvisioningCriteriaMutation,
  useDeleteProvisioningCriteriaMutation,
  useLazyRetrieveProvisioningCriteriaDetailQuery,
  useRetrieveProvisioningCriteriaDetailQuery,
  useGetProvisioningCriteriaQuery,
  useLazyGetOfficeTemplateQuery,
  useUpdateDatatableMutation,
  useReactivateClientMutation,
  useChangeClientStatusMutation,
  useCreateCodeMutation,
  useUpdateCodeMutation,
  useLazyGetLoansForApprovalQuery,
  useLazyGetLoansForDisbursalQuery,
  useLazyGetActiveLoansQuery,
  useLazyGetStandingInstructionsTemplateQuery,
  useLazyGetLoanStaffTemplateQuery,
  useLazyGetCodeQuery,
  useLazyGetLoanCollateralTemplateQuery,
  useLazyGetLoanChargeTemplateQuery,
  useLazyGetCodeValuesQuery,
  useLazyGetCodesQuery,
  useLazyGetSavingsAccountDetailsQuery,
  useLazyGetSavingsTransactionTemplateQuery,
  useLazyGetConfigurationsQuery,
  useGetConfigurationDetailsQuery,
  useLazyGetClientImagesQuery,
  useLazyGetClientLoansQuery,
  useLazyGetClientNotesQuery,
  useLazyGetClientFamilyQuery,
  useLazyGetClientIdentifiersQuery,
  useLazyGetClientAddressesQuery,
  useActivateShareAccountNoRefetchMutation,
  useApplyForSharesNoRefetchMutation,
  useApproveAdditionalSharesNoRefetchMutation,
  useApproveShareAccountNoRefetchMutation,
  useCreateShareAccountNoRefetchMutation,
  useRejectAdditionalSharesNoRefetchMutation,
  useActivateSmsCampaignMutation,
  useDeactivateSmsCampaignMutation,
  useDeleteSmsCampaignMutation,
  useReactivateSmsCampaignMutation,
  useUpdateSmsCampaignMutation,
  useCreateSmsCampaignMutation,
  useGetSmsCampaignsQuery,
  useGetStaffEditTemplateQuery,
  useLazyGetStaffEditTemplateQuery,
  useGetSmsCampaignsTemplateQuery,
  useGetMakerCheckersQuery,
  useDeleteMakerCheckerMutation,
  useRejectMakerCheckerMutation,
  useApproveMakerCheckerMutation,
  useLazyGetAuditsQuery,
  useGetAuditSearchTemplateQuery,
  useAddLoanCollateralMutation,
  useUpdateLoanCollateralMutation,
  useGetLoanCollateralQuery,
  useGetLoanCollateralTemplateQuery,
  useGetChargeDetailQuery,
  useLazyGetChargeDetailQuery,
  useLazyGetSavingsProductsQuery,
  useLazyGetClientsQuery,
  useDeleteReportMutation,
  useLazyGetOfficesQuery,
  useLazyGetAccountTemplateQuery,
  useGetMakerCheckerQuery,
  useUpdateMakerCheckerMutation,
  useWriteOffLoanMutation,
  useGetLoanStaffTemplateQuery,
  useAddClientNoteMutation,
  useGetClientNotesQuery,
  useRetrieveSavingsProductQuery,
  useAddLoanNoteMutation,
  useGetAccountNumberFormatQuery,
  useLazyGetAccountNumberFormatQuery,
  useCreateAccountNumberFormatMutation,
  useGetAccountTemplateQuery,
  useUpdateAccountNumberFormatMutation,
  useLazyGetChildParameterValuesQuery,
  useCreateLoanGuarantorMutation,
  useUpdateRecurringDepositProductMutation,
  useLazyGetRecurringDepositProductTemplateQuery,
  useCreateRecurringDepositProductMutation,
  useGetRecurringDepositProductsQuery,
  useGetRecurringDepositAccountTemplateQuery,
  useLazyGetRecurringDepositAccountProductTemplateQuery,
  useLazyRetrieveShareAccountQuery,
  useLazyGetShareProductQuery,
  useGetShareProductQuery,
  useDeleteLoanProductMixMutation,
  useGetClientChargesQuery,
  useLazyGetClientTemplateQuery,
  useGetClientTemplateQuery,
  useGetClientsTemplateQuery,
  useLazyGetClientsTemplateQuery,
  useLazyGetStaffInSelectedBranchQuery,
  useGetAccountQuery,
  useGetClientQuery,
  useGetClosuresQuery,
  useGetAccountEntriesQuery,
  useLazyGetClosuresQuery,
  useCreateClosureMutation,
  useDeleteClosureMutation,
  useGetClientFamilyQuery,
  useAddClientFamilyMutation,
  useGetClientChargesTemplateQuery,
  useLazyGetChargeTemplateQuery,
  useAddClientChargeMutation,
  usePayClientChargeMutation,
  useWaiveClientChargeMutation,
  useDeleteClientChargeMutation,
  useSettleCashierMutation,
  useAllocatedCashToCashierMutation,
  useGetCurrenciesQuery,
  useUpdateCurrenciesMutation,
  useGetWorkingDaysQuery,
  useUpdateWorkingDaysMutation,
  useGetHolidaysQuery,
  useGetLoanProductMixesQuery,
  useGetLoanProductMixTemplateForAProductQuery,
  useLazyGetLoanProductMixTemplateForAProductQuery,
  useCreateHolidayMutation,
  useUpdateHolidayMutation,
  useActivateHolidayMutation,
  useDeleteHolidayMutation, 
  useGetTellerCashierSummaryTransactionsQuery,
  useLazyGetTellerCashierSummaryTransactionsQuery,
  useGetTellerCashierTransactionsQuery,
  useGetTellerCashierQuery,
  useCreateTellerCashierMutation,
  useUpdateTellerCashierMutation,
  useGetTellerCashiersQuery,
  useGetClientLoansQuery,
  useGetClientSharesQuery,
  useLazyGetClientSharesQuery,
  useLazyGetShareAccountsQuery,
  useGetClientSavingsQuery,
  useLazyGetClientSavingsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  usePaySavingsChargeMutation,
  useWaiveSavingsChargeMutation,
  useCreateSavingsChargeMutation,
  useDeleteSavingsChargeMutation,
  useLazyRunReportQuery,
  useLazyRunLoanTransactionReceiptQuery,
  useLazyRunSavingsTransactionReceiptQuery,
  useLazyRunPdfReportQuery,
  useGetReportParameterValuesQuery,
  useLazyGetReportParameterValuesQuery,
  useGetReportParameterListQuery,
  useLazyGetReportParameterListQuery,
  useGetReportDetailQuery,
  useLazyGetReportDetailQuery,
  useCreateFinancialActivityAccountMutation,
  useUpdateFinancialActivityAccountMutation,
  useGetFinancialActivityAccountsTemplateQuery,
  useLazyGetFinancialActivityAccountQuery,
  useRejectLoanApplicationMutation,
  useGetSavingsAccountChargesQuery,
  useCloseSavingsAccountMutation,
  useCreateTellerMutation,
  useGetTellersQuery,
  useUpdateTellerMutation,
  useGetTellerQuery,
  useLazyGetTellerQuery,
  useCreateFDAccountMutation,
  useGetFixedDepositAccountTemplateQuery,
  useLazyRetrieveLoanProductQuery,
  useRepayLoanMutation,
  useAssignStaffLoanMutation,
  useAssignStaffClientMutation,
  useLazyGetStaffDetailQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useWaiveLoanIntrestMutation,
  useGetLoanTransactionsTemplateQuery,
  useCreateFixedDepositProductMutation,
  useUpdateFixedDepositProductMutation,
  useRetrieveFixedDepositProductQuery,
  useGetFixedDepositProductsQuery,
  useGetFixedDepositProductTemplateQuery,
  useLazyGetFixedDepositProductTemplateQuery,
  useActivateShareAccountMutation,
  useApproveShareAccountMutation,
  useUndoApproveShareAccountMutation,
  useRejectShareAccountMutation,
  useGetSavingsAccountTemplateQuery,
  useApproveAdditionalSharesMutation,
  useRejectAdditionalSharesMutation,
  useRedeemSharesMutation,
  useCreateShareAccountWithSavingsMutation,
  useApplyForSharesMutation,
  useApplyForSharesWithSavingsMutation,
  useGetShareAccountQuery,
  useLazyGetShareAccountQuery,
  useGetShareAccountsQuery,
  useCreateShareAccountMutation,
  useCloseShareAccountMutation,
  useGetShareAccountTemplateQuery,
  useGetSharesProductTemplateQuery,
  useGetShareProductDividendsQuery,
  useInitiateShareProductDividendsMutation,
  useLazyGetSharesProductTemplateQuery,
  useGetSavingsProductTemplateQuery,
  useCreateLoanChargeMutation,
  useWaiveLoanChargeMutation,
  usePayLoanChargeMutation,
  useDeleteLoanChargeMutation,
  useGetLoanChargeTemplateQuery,
  useCreateAccountTransferMutation,
  useGetAccountTransferTemplateQuery,
  useLazyGetAccountTransferTemplateQuery,
  useGetAccountRefundByTransferTemplateQuery,
  useLazyGetAccountRefundByTransferTemplateQuery,
  useUndoDisbursalMutation,
  useGetStandingInstructionsTemplateQuery,
  useCreateStandingInstuctionsMutation,
  useDeleteStandingInstructionMutation,
  useUpdateStandingInstructionMutation,
  useGetStandingInstructionsRunHistoryForClientQuery,
  useGetStandingInstructionsRunHistoryQuery,
  useLazyGetSavingsAccountChargesQuery,
  useGetSavingsChargeTemplateQuery,
  useGetStandingInstructionsQuery,
  useGetAccountTransfersQuery,
  useUndoTransactionMutation,
  useReverseTransactionsMutation,
  useLazyGetLoanDetailsQuery,
  useCreateTransactionMutation,
  useCreateAccountingRuleMutation,
  useUpdateAccountingRuleMutation,
  useUpdateOfficeMutation,
  useLazyRetrieveOfficeQuery,
  useCreateOfficeMutation,
  useLazyGetAccountEntriesQuery,
  useGetPaymentTypesQuery,
  useCreatePaymentTypeMutation,
  useUpdatePaymentTypeMutation,
  useLazyGetExistingLoanTemplateQuery,
  useUnAssignLoanOfficerMutation,
  useUndoApprovalMutation,
  useUpdateLoanMutation,
  useGetLoanProductMixTemplateQuery,
  useLazyGetLoanProductMixTemplateQuery,
  useGetNewLoanTemplateQuery,
  useLazyGetNewLoanTemplateQuery,
  useRetrieveLoanProductQuery,
  useCreateLoanMutation,
  useApproveLoanMutation,
  useAssignLoanOfficerMutation,
  useCalculateLoanScheduleMutation,
  useCreateLoanProductMutation,
  useUpdateLoanProductMutation,
  useDisburseLoanMutation,
  useRescheduleLoanMutation,
  useDisburseLoanToSavingsMutation,
  useCreateGLAccountMutation,
  useUpdateGLAccountMutation,
  useGetSavingsTransactionTemplateQuery,
  useWithdrawFundsMutation,
  useUpdateRecommendedDepositAmountMutation,
  useHoldAmountMutation,
  useReleaseAmountMutation,
  useDepositFundsMutation,
  useDepositFundsNoRefetchMutation,
  useGetSavingsAccountDetailsQuery,
  useGetClientAccountsQuery,
  useCreateSavingsApplicationMutation,
  useApproveSavingsApplicationMutation,
  useActivateSavingsApplicationMutation,
  useGetSavingsApplicationsQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useGetCloseClientTemplateQuery,
  useCloseClientMutation,
  useProposeClientTransferMutation,
  useAcceptClientTransferMutation,
  useGetLoginTokenMutation,
  useRunJobMutation,
  useGetAccountingRulesQuery,
  useGetJournalEntriesQuery,
  useGetJobsQuery,
  useUpdateJobMutation,
  useGetAuditsQuery,
  useCreateSharesProductMutation,
  useGetSharesProductsQuery,
  useLazyGetSharesProductsQuery,
  useUpdateSharesProductMutation,
  useCreateSavingsProductMutation,
  useGetSavingsProductsQuery,
  useUpdateSavingsProductMutation,
  useLazyGetSavingsProductTemplateQuery,
  useUpdateChargeMutation,
  useCreateChargeMutation,
  useGetChargesQuery,
  useGetConfigurationsQuery,
  useLazyGetConfigurationDetailsQuery,
  useUpdateConfigurationsMutation,
  useGetDatatablesQuery,
  useCreateDatatableMutation,
  useGetFinancialActivityAccountsQuery,
  useGetOfficesQuery,
  useGetReportsQuery,
  useGetRolesQuery,
  useApproveDividendMutation,
  useGetDividendDetailsQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useGetRolePermissionsQuery,
  useUpdateRolePermissionsMutation,
  useGetStaffQuery,
  useLazyGetOfficeStaffQuery,
  useGetOfficeStaffQuery,
  useGetUsersQuery,
  useGetUserTemplateQuery,
  useLazyGetTemplateQuery,
  useLazyGetUserTemplateQuery,
  useLazyGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetAccountsQuery,
  useGetPlainAccountsQuery,
  useLazyRetrieveAccountQuery,
  useGetManualEntryAccountsQuery,
  useGetFundsQuery,
  useCreateFundMutation,
  useUpdateFundMutation,
  useGetLoanProductsQuery,
  useLazyGetFixedDepositAccountProductTemplateQuery,
  useGetLoanProductTemplateQuery,
  useLazyGetLoanProductTemplateQuery,
  useGetTemplateQuery,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useUpdateNotificationsMutation,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useGetGroupDetailsQuery,
  useGetGroupAccountsQuery,
  useAssociateGroupMembersMutation,
  useGetGroupTemplateQuery,
  useAssignGroupRoleMutation,
  useUnassignGroupRoleMutation,
  useDisassociateGroupMembersMutation,
  useGetLoansQuery,
  useGetLoansForApprovalQuery,
  useGetLoansForDisbursalQuery,
  useGetActiveLoansQuery,
  useGetOverdueLoansQuery,
  useLazyGetClientOverdueLoansQuery,
  useGetClientOverdueLoansQuery,
  useLazyGetClientQuery,
  useGetOverdueNPALoansQuery,
  useGetLoanDetailsQuery,
  useGetClientsQuery,
  useGetClientAddressesQuery,
  useCreateClientAddressMutation,
  useGetClientIdentifiersQuery,
  useCreateClientIdentifierMutation,
  useGetClientImagesQuery,
  useUploadClientImageMutation,
  useGetCodesQuery,
  useGetCodeQuery,
  useGetCodeValuesQuery,
  useCreateCodeValueMutation,
  useUpdateCodeValueMutation,
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useGetExternalServiceQuery,
  useUpdateExternalServiceMutation
} = fineractApi;

export const membersApi = createApi({
  reducerPath: 'membersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `http://localhost:8000/api/v1/saccos/1/`
  }),
  endpoints: (builder) => ({
    getMembers: builder.query({
      query: () => `membernumbers`
    })
  })
});

export const { useGetMembersQuery } = membersApi;

export const registrationApi = createApi({
  reducerPath: 'registrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: registrationUrl
  }),
  endpoints: (builder) => ({
    getRegistrations: builder.query({
      query: () => `/`
    })
  })
});

export const { useGetRegistrationsQuery } = registrationApi;

export const sanitizeReportData = (data) => {
  
  return data.data.map((parameter) => {
    let item = {};
    data.columnHeaders.map((column, j) => {
      item[column.columnName] =parameter.row[j];
    });
    return item;
  });
};

export const mapRowsAndColumns = (data) => {
  const rows = data.data;
  const columns = data.columnHeaders;
  return rows.map(a =>{
    const parameter = {};
    a.row.map((b,i)=>parameter[columns[i].columnName] = b);
    return parameter;
  })
};

export const convertToWords = num => {
  const ones = ['','one','two','three','four','five','six','seven','eight','nine'];
  const tens = ['','','twenty','thirty','forty','fifty','sixty','seventy','eighty','ninety'];
  const teens = ['ten','eleven','twelve','thirteen','fourteen','fifteen','sixteen','seventeen','eighteen','nineteen'];
  const scales = ['','thousand','million','billion','trillion'];

  if (num == 0) return 'zero';

  let words = '';
  let i = 0;

  do {
    let chunk = num % 1000;
    if (chunk !== 0) {
      let chunkWords = '';
      chunkWords += (chunk < 10) ? ones[chunk] 
                  : (chunk < 20) ? teens[chunk - 10]
                  : (chunk < 100) ? `${tens[Math.floor(chunk/10)]} ${ones[chunk % 10]}`
                  : `${ones[Math.floor(chunk/100)]} hundred ${tens[Math.floor((chunk % 100)/10)]} ${ones[chunk % 10]}`;

      words = `${chunkWords} ${scales[i]} ${words}`;
    }

    i++;
    num = Math.floor(num / 1000);
  } while (num > 0);

  return words.trim();
}
