import { combineReducers } from '@reduxjs/toolkit';

/* import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as dataTableReducer } from 'src/slices/datatable';
import { reducer as clientsReducer } from 'src/slices/client';
import { reducer as codesReducer } from 'src/slices/code';
import { reducer as officesReducer } from 'src/slices/office';
import { reducer as staffReducer } from 'src/slices/staff';
import { reducer as loansReducer } from 'src/slices/loans';
import { reducer as clientImagesReducer } from 'src/slices/clientImages';
import { reducer as groupReducer } from 'src/slices/groups';
import { reducer as loanProductsReducer } from 'src/slices/loanProduct';
import { reducer as fundsReducer } from 'src/slices/fund';
import { reducer as accountsReducer } from 'src/slices/account';
import { reducer as usersReducer } from 'src/slices/user';
import { reducer as rolesReducer } from 'src/slices/role';
import { reducer as configurationsReducer } from 'src/slices/configuration';
import { reducer as activitiesReducer } from 'src/slices/financialactivity';
import { reducer as chargesReducer } from 'src/slices/charges';
import { reducer as reportsReducer } from 'src/slices/reports';
import { reducer as savingsProductsReducer } from 'src/slices/savingsProduct';
import { reducer as sharesProductsReducer } from 'src/slices/sharesProduct';
import { reducer as auditsReducer } from 'src/slices/audits';
import { reducer as membersReducer } from 'src/slices/member';
import { reducer as shareAccountReducer } from 'src/slices/shareAccount'; */
import { fineractApi, membersApi, registrationApi } from 'src/services/api';
/* import { reducer as savingsApplicationReducer } from 'src/slices/savingsApplication';
import { reducer as paymentTypesReducer } from 'src/slices/paymentType';
import { reducer as ruleReducer } from 'src/slices/rule';
import { reducer as instructionReducer } from 'src/slices/instruction';
import { reducer as registrationReducer } from 'src/slices/registration'; */

const rootReducer = combineReducers({
  [fineractApi.reducerPath]: fineractApi.reducer,
  [membersApi.reducerPath]: membersApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
  /* rule: ruleReducer,
  instruction: instructionReducer,
  savingsApplication: savingsApplicationReducer,
  user: usersReducer,
  paymentType: paymentTypesReducer,
  member: membersReducer,
  charge: chargesReducer,
  activity: activitiesReducer,
  configuration: configurationsReducer,
  role: rolesReducer,
  report: reportsReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  fund: fundsReducer,
  shareAccount: shareAccountReducer,
  account: accountsReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notification: notificationReducer,
  dataTable: dataTableReducer,
  client: clientsReducer,
  code: codesReducer,
  staff: staffReducer,
  office: officesReducer,
  loan: loansReducer,
  clientImages: clientImagesReducer,
  group: groupReducer,
  loanProduct: loanProductsReducer,
  savingsProduct: savingsProductsReducer,
  sharesProduct: sharesProductsReducer,
  audit: auditsReducer,
  registration: registrationReducer */
});

export default rootReducer;
