import React, { createContext, useEffect, useReducer, useState } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { useGetLoginTokenMutation, useLazyGetUserDetailsQuery } from 'src/services/api';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('fineractAccessToken', accessToken);
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'BHA',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  isLoading: false,
  loginError: null
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [userDetails, setUserDetails] = useState(null);
  const [loginError, setLoginError] = useState(null);

  const [attemptLogin, { isError, isLoading:isGettingToken }] =
    useGetLoginTokenMutation();
  const [getUser,{isFetching,isLoading:isLoadingUser}] = useLazyGetUserDetailsQuery();

  const login = async (username, password) => {
    

    attemptLogin({ username, password })
      .unwrap()
      .then(
        (data) => {
          
          setSession(JSON.stringify(data));
          dispatch({
            type: 'LOGIN',
            payload: {
              user: data
            }
          });

          //getUser(data.userId).unwrap().then(user => setUserDetails(user))
          
        },
        (error) => {
          setLoginError(error);
        }
      );
  };

  const logout = () => {
    localStorage.removeItem('fineractAccessToken');
    setSession(null);
    dispatch({ type: 'LOGOUT', payload:{isAuthenticated:false,user:null} });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('fineractAccessToken');

        if (accessToken) {
          if (accessToken) {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user: JSON.parse(accessToken)
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'BHA',
        login,
        userDetails,
        setUserDetails,
        logout,
        isError,
        isLoading: isGettingToken || isLoadingUser || isFetching,
        register,
        loginError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
