import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  Fab,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';
import AdjustmentsIcon from '../icons/Adjustments';

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
  currencyCode: settings.currencyCode,
  dateMonthFormat:settings.dateMonthFormat,
  locale:settings.locale,
  dateFormat:settings.dateFormat,
  fullname:process.env.orgName?process.env.orgName:settings.fullname,
  tenant:settings.tenant
});

const SettingsDrawer = () => {
  const { settings, saveSettings } = useSettings();
  
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(getValues(settings));
  

  

  

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Application Settings">
        <Fab
          color="primary"
          onClick={handleOpen}
          size="medium"
          sx={{
            bottom: 0,
            margin: (theme) => theme.spacing(4),
            position: 'fixed',
            left: 0,
            zIndex: (theme) => theme.zIndex.speedDial
          }}
        >
          <AdjustmentsIcon fontSize="small" />
        </Fab>
      </Tooltip>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            width: 320
          }
        }}
      >
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Application Settings
        </Typography>
        
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Short Name"
            name="tenant"
            onChange={(event) => handleChange('tenant', event.target.value)}
            
            value={values.tenant}
            variant="outlined"
          />
            
          
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            InputProps={{readOnly:process.env.orgName}}
            label="Full Name"
            name="fullname"
            onChange={(event) => handleChange('fullname', event.target.value)}
            
            value={values.fullname}
            variant="outlined"
          />
            
          
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Currency Code"
            name="currencyCode"
            onChange={(event) => handleChange('currencyCode', event.target.value)}
            
            value={values.currencyCode}
            variant="outlined"
          />
            
          
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Locale"
            name="locale"
            onChange={(event) => handleChange('locale', event.target.value)}
            
            value={values.locale}
            variant="outlined"
          />
            
          
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Date Format"
            name="dateFormat"
            onChange={(event) => handleChange('dateFormat', event.target.value)}
            
            value={values.dateFormat}
            variant="outlined"
          />
            
          
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Date Month Format"
            name="dateMonthFormat"
            onChange={(event) => handleChange('dateMonthFormat', event.target.value)}
            
            value={values.dateMonthFormat}
            variant="outlined"
          />
            
          
        </Box>
         <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {theme
                  .split('_')
                  .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                  .join(' ')}
              </option>
            ))}
          </TextField>
        </Box> 
        
        {/* <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.direction === 'rtl'}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event) => handleChange('direction', event.target.checked
                  ? 'rtl'
                  : 'ltr')}
              />
            )}
            label={(
              <div>
                RTL
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Change text direction
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.responsiveFontSizes}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
              />
            )}
            label={(
              <div>
                Responsive font sizes
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Adjust font for small devices
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.compact}
                color="primary"
                edge="start"
                name="compact"
                onChange={(event) => handleChange('compact', event.target.checked)}
              />
            )}
            label={(
              <div>
                Compact
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Fixed width on some screens
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.roundedCorners}
                color="primary"
                edge="start"
                name="roundedCorners"
                onChange={(event) => handleChange('roundedCorners', event.target.checked)}
              />
            )}
            label={(
              <div>
                Rounded Corners
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Increase border radius
                </Typography>
              </div>
            )}
          />
        </Box> */}
        <Box sx={{ mt: 3 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleSave}
            variant="contained"
          >
            Save Settings
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
