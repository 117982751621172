//v4 begins here

import "react-perfect-scrollbar/dist/css/styles.css";
import "nprogress/nprogress.css";
import React, { StrictMode } from "react";


import { Provider as ReduxProvider } from "react-redux";

import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import AppLayout from "../src/App";
import { AuthProvider } from "../src/contexts/BasicHTMLAuthContext";
import { SettingsProvider } from "../src/contexts/SettingsContext";
import store from "../src/store";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';




export default function App({ Component, pageProps }) {
  return (
    <StrictMode>
      
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <AuthProvider>
                  <AppLayout>
                    <Component {...pageProps} />
                  </AppLayout>
                </AuthProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      
    </StrictMode>
  );
}
