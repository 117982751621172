import throttle from 'lodash/throttle';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { fineractApi,registrationApi } from 'src/services/api';

import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';
import rootReducer from './rootReducer';
import { loadState,saveState } from './localStorage';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

const persistedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(fineractApi.middleware).concat(registrationApi.middleware)
});

 store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 120000)
); 

setupListeners(store.dispatch)

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
