

import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AuthProvider } from 'src/contexts/BasicHTMLAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createCustomTheme } from 'src/theme';
import { Idle } from './contexts/IdleTimer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from 'react-error-boundary';
import FallBackComponent from './views/errors/FallBackComponent';
import RTL from './components/RTL';
import SettingsDrawer from './components/SettingsDrawer';



const App = ({ children }) => {
  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline/>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider dense maxSnack={3}>
              <SettingsDrawer/>
              <AuthProvider>
                
                
                <Idle />
                <ErrorBoundary FallbackComponent={FallBackComponent} onReset={()=>{}}>
                
                {children}
                </ErrorBoundary>
              </AuthProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        
        </RTL>
      </ThemeProvider>
    
  );
};

export default App;
