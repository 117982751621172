import { useIdleTimer } from 'react-idle-timer';
import useAuth from 'src/hooks/useAuth';

export function Idle() {
  const { logout } = useAuth();


  const onIdle = () => {
    logout();
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const {} = useIdleTimer({
    timeout: 25 * 1000 * 60,
    onIdle,
    onActive,
    onAction
  });

  return <></>;
}
